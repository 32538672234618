import React, { FunctionComponent } from "react";
import { FacebookProvider, Comments as FacebookComment } from 'react-facebook';
import useSiteMetadata from "@ciepo/mag-theme/src/hooks/use-site-metadata";
import styled from "styled-components";

const CommentWrapper = styled.div`
  padding: 40px;
`;

const Comments: FunctionComponent = (props: any) => {
  const { siteUrl } = useSiteMetadata();
  const [pathname, setPathname] = React.useState('');

  // Little hack for serverside rendering
  React.useEffect(() => {
    const path = typeof window !== 'undefined' && window.location.pathname;
    if (path) setPathname(path)
  }, []);

  if (!pathname) return null;

  // The old comments is stored using http instead of https
  const oldSiteUrl = siteUrl.replace('https', 'http')

  return (
    <CommentWrapper>
      <FacebookProvider appId={'355718374556193'}>
        <FacebookComment href={`${oldSiteUrl}${pathname}`} />
      </FacebookProvider>
    </CommentWrapper>
  )
};

export default Comments;
