import * as React from 'react';
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterIcon,
    TwitterShareButton,
    LinkedinShareButton,
    LinkedinIcon,
    WhatsappShareButton,
    WhatsappIcon
} from 'react-share';
import styled from 'styled-components';
import useSiteMetadata from '../../hooks/use-site-metadata';

interface OutterProps {
    title: string;
    pathname: string;
}

const ShareContainer = styled.div`
    padding: 0 40px;
`;

const ShareButton = (props: OutterProps) => {
    const { siteUrl } = useSiteMetadata();
    const url = `${siteUrl}${props.pathname}`
    return (
        <ShareContainer>
            <h3>Bagikan!</h3>
            <FacebookShareButton url={url}>
                <FacebookIcon />
            </FacebookShareButton>
            <TwitterShareButton url={url} title={'Ciepo'}>
                <TwitterIcon />
            </TwitterShareButton>
            <LinkedinShareButton url={url}>
                <LinkedinIcon />
            </LinkedinShareButton>
            <WhatsappShareButton url={url}>
                <WhatsappIcon />
            </WhatsappShareButton>
        </ShareContainer>
    )
}
ShareButton.displayName = 'ShareButton';

export default ShareButton;