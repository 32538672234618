import React, {FunctionComponent} from "react";
import {graphql, useStaticQuery} from "gatsby";
import {Card} from "../card";
import styled from "styled-components";
import Theme from "../../styles/theme";
import TrendingList from "../trending-list";

const LatestPosts = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
  width: 280px;

  @media (max-width: ${Theme.breakpoints.md}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`;

const PageSidebarContent: FunctionComponent = () => {
  const results = useStaticQuery(graphql`
    query {
      recentPosts: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(posts)/.*\\\\.md$/" } }
        sort: { fields: frontmatter___created, order: DESC }
        limit: 3
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              path
              categories
              created
              createdPretty: created(formatString: "DD MMMM, YYYY")
              excerpt
            }
            featuredImage {
              childImageSharp {
                fixed(width: 280, height: 89) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  `);
  
  const recentPosts = results.recentPosts.edges.map(node => node.node);
  
  return (
    <>
      <h3>Terbaru</h3>
      <LatestPosts>
        {recentPosts.map((post, index) => (
          <Card
            key={index}
            compact={true}
            path={post.frontmatter.path}
            title={post.frontmatter.title}
            featuredImage={post.featuredImage.childImageSharp}
            meta={
              {
                time: post.frontmatter.created,
                timePretty: post.frontmatter.createdPretty,
                category: post.frontmatter.categories.length > 0 ? post.frontmatter.categories[0] : null,
              }
            }
          />
        ))}
      </LatestPosts>
      <TrendingList />
    </>
  );
};

export default PageSidebarContent;
